<template>
  <moe-page title="新建赠品">
    <moe-form
      class="default-form"
      ref="giftForm"
      :showBack="false"
      :showClose="true"
      :model="giftParams"
      :rules="rules">
      <div style="width: 50%;" class="margin-0-auto">
        <el-form-item label="赠品名称" prop="name">
          <el-input class="w-300" v-model.trim="giftParams.name" placeholder="请输入赠品名称" maxlength="20" clearable></el-input>
        </el-form-item>
      </div>

      <div style="height: 300px;min-height: 300px;" class="df fdc mb-30">
        <moe-table
          :stripe="true"
          :numberShow="false"
          :data="giftParams.goodsList"
          :mode="false"
          :showPage="false"
          :params="{ pageNum: 1 }"
          emptyText="请添加活动商品"
          rowKey="goodsId">
          <template slot="tool">
            <el-form-item prop="goodsList">
              <el-button :disabled="IS_EDIT" type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加商品</el-button>
            </el-form-item>
          </template>

          <el-table-column label="赠品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="df1 tal ml-10">
                  <p class="fwb">{{ row.name }}</p>
                  <p class="font-12 color-info">商品编号: {{ row.id }}</p>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="活动规格" min-width="200">
            <div class="df aic jcc" slot-scope="{ row, $index }">
              <el-form-item label-width="0" class="mt-20" :prop="`goodsList.${$index}.specList`" :rules="rules.specList">
                <div class="df aic jcc" v-if="row.specList.length">
                  <div class="df aic mr-5" v-for="(item, index) in row.specList" :key="index">
                    <moe-tag class="mr-5" v-for="(specItem, specIndex) in item.specValueStr.split(',')" :key="specIndex">{{ specItem }}</moe-tag>
                  </div>
                  <el-button icon="el-icon-edit" size="small" type="warning" @click="req_queryShopGoodsById('add', row, $index)">修改</el-button>
                </div>
                <el-button v-else icon="el-icon-circle-plus-outline" size="small" type="warning" @click="req_queryShopGoodsById('add', row, $index)">选择规格</el-button>
              </el-form-item>
            </div>
          </el-table-column>

          <el-table-column label="活动剩余库存" prop="stockLeft" min-width="120" />
          <el-table-column label="活动总库存" prop="stockTotal" min-width="120" />

          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="{ row, $index }">
              <el-button :disabled="IS_EDIT" icon="el-icon-delete" size="small" type="danger" @click="handleDelete(row, $index)">撤出活动</el-button>
            </template>
          </el-table-column>
        </moe-table>
      </div>

      <div style="width: 50%;" class="margin-0-auto">
        <el-form-item label="领取有效期" prop="validDays">
          <div class="df aic wsn">
            <el-input class="w-300" :value="giftParams.validDays" @input="(value) => giftParams.validDays = $moe_formatter.formatterInteger(value)" placeholder="请输入天数" maxlength="50" clearable>
              <template slot="append">天</template>
            </el-input>
            <el-tooltip class="ml-10" content='赠品通过抽奖等方式赠送后，客户需在有效期内领取，过期后将无法领取' placement="top">
              <moe-icon name="reminder" size="24px"></moe-icon>
            </el-tooltip>
          </div>
        </el-form-item>
      </div>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="giftLoad" @click="handleSubmit()">{{ giftLoad ? '保存中' : '提交' }}</el-button>
      </template>
    </moe-form>

    <goodsDialog
      :multiple="false"
      :showDialog.sync="showDialog"
      type="BUY_GIFT"
      :defaultSelectIds="giftParams.goodsList && giftParams.goodsList.length ? giftParams.goodsList.map(({ id }) => id) : []"
      @close="handleClose">
    </goodsDialog>

    <moe-dialog :show="form.showDialog" title="规格赠品选择" width="70%" @close="handleDialogClose()" :autoHeight="true">
      <div class="dialog_content">
        <moe-form
          ref="specForm"
          class="default-form"
          :showBack="false"
          :showTool="false"
          :model="form"
          :rules="rules">
          <moe-table
            url=""
            :maxHeight="480"
            :data="form.specList"
            :numberShow="false"
            :mode="false"
            :params="{ pageNum: 1 }"
            rowKey="id"
            :multiple="false"
            :tableRowClassName="tableRowClassName"
            :row-class-name="tableRowClassName"
            :defaultSelectIds="form.defaultSelectIds"
            @selectChange="selectChange">
            <el-table-column :selectable="selectable" type="selection" reserve-selection label="id" width="60" />
            <el-table-column label="编号" prop="id" width="80" />
            <el-table-column label="规格" min-width="150">
              <template slot-scope="{ row }">
                <div class="df aic jcc">
                  <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="销售价" prop="salePrice" min-width="150">
              <template slot-scope="{ row }">
                {{ `¥ ${row.salePrice}` }}
              </template>
            </el-table-column>
            <el-table-column label="库存" prop="stock" min-width="80" />
            <el-table-column label="活动库存" min-width="150">
              <template slot-scope="{ row, $index }">
                <el-form-item class="mt-20" :prop="`specList.${$index}.stockTotal`" :rules="rules.stockTotal">
                  <el-input :disabled="stockTotalDisabledComputed(row)" :value="row.stockTotal" @input="(value) => row.stockTotal = $moe_formatter.formatterStock(value)" placeholder="请输入活动库存" maxlength="10" clearable />
                </el-form-item>
              </template>
            </el-table-column>
          </moe-table>
        </moe-form>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityManageGiftManageAdd',
  components: {
    goodsDialog
  },
  computed: {
    /** 新增 | 编辑 */
    IS_EDIT() {
      if (this.giftParams.id) {
        return true
      } else {
        return false
      }
    },
    /** 已使用的商品sku */
    useSpecList() {
      return this.form.useSpecList;
    }
  },
  data() {
    const checkStockTotal = (rule, value, callback) => {
      const index = rule.fullField.split('.')[1];
      const findItem = this.form.specList[index];
      if (!value) {
        if (this.form.selectList.length) {
          if (this.form.selectList.filter(({ id }) => findItem.id === id).length) {
            callback(new Error(`请输入活动库存`));
          } else {
            callback();
          }
        } else {
          callback(new Error(`请输入活动库存`));
        }
      } else {
        if (this.$moe_math.mathGreaterThan(value, findItem.stock)) {
          callback(new Error(`活动库存大于规格库存`));
        } else {
          callback();
        }
      }
    }
    const verify = this.$moe_verify.verifyForm;
    return {
      giftParams: {
        id: '',
        name: '',
        goodsId: '',
        goodsItemId: '',
        stockTotal: '',
        validDays: '',
        goodsList: [],
      },
      rules: {
        name: verify.isExtent(['请输入活动名称', '长度为 2 ~ 16位'], '2~16'),
        goodsList: verify.isEmpty('请添加活动商品'),
        specList: verify.isEmpty('请选择活动规格'),
        stockTotal: [{ required: true, validator: checkStockTotal, trigger: ['blur', 'change'] }],
        validDays: verify.isEmpty('请输入领取有效期天数'),
      },
      showDialog: false,
      giftLoad: false,
      form: {
        showDialog: false,
        goodsId: '',
        defaultSelectIds: [],
        selectList: [],
        specList: [],
        useSpecList: [],
      }
    }
  },
  methods: {
    handleClose(selectList) {
      if (this.giftParams.goodsList.length) {
        return this.$moe_msg.warning('最多添加一个活动商品');
      }
      selectList.forEach((item) => {
        let findItem = this.giftParams.goodsList.find(({ id, goodsId }) => item.id === id || item.id === goodsId);

        if (!findItem) {
          this.giftParams.goodsList.push({
            ...item,
            specList: [],
            stockLeft: 0,
            stockTotal: 0,
          });
        }
      })

      this.showDialog = false;
    },
    /** 提交 */
    handleSubmit() {
      this.$refs['giftForm'].validate(() => {
        this.giftLoad = true;
        const { id, name, validDays, goodsList } = this.giftParams
        let params = {
          id,
          name,
          goodsId: goodsList[0].id,
          goodsItemId: goodsList[0].specList[0].id,
          stockTotal: goodsList[0].stockTotal,
          validDays,
        };
        if (this.IS_EDIT) {
          this.req_updateGift(params);
        } else {
          this.req_addGift(params);
        }
      })
    },
    selectChange(list) {
      this.form.selectList = list;
      try {
        this.$refs['specForm'].validate();
      } catch {
        console.log('error');
      }
    },
    handleDialogClose() {
      this.form.showDialog = false;
      this.$refs['specForm'].clearValidate();
    },
    /** 规格赠品设置 */
    handleConfirm() {
      this.$refs['specForm'].validate(() => {
        const { goodsId, specList, selectList } = this.form;
        if (!selectList.length) {
          return this.$moe_msg.warning('请选择规格');
        }
        let findIndex = this.giftParams.goodsList.findIndex(({ id }) => goodsId === id);
        let selectItem = specList.find(({ id }) => selectList[0].id === id);
        if (selectItem) {
          this.giftParams.goodsList[findIndex].specList = [selectItem];
          this.giftParams.goodsList[findIndex].stockLeft = selectItem.stockTotal;
          this.giftParams.goodsList[findIndex].stockTotal = selectItem.stockTotal;
        }
        this.form.showDialog = false;
        try {
          this.$refs['giftForm'].validateField(`goodsList.${findIndex}.specList`);
        } catch {
          console.log('error');
        }
      })
    },
    handleDelete({ name }, index) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}”吗？`, () => {
        this.giftParams.goodsList.splice(index, 1);
      });
    },
    stockTotalDisabledComputed(row) {
      let boolean = false
      if (this.form.selectList.length) {
        if (this.form.selectList.filter(({ id }) => row.id === id).length) {
          boolean = false
        } else {
          boolean = true
        }
      } else {
        boolean = false
      }
      if (this.useSpecList.length && this.useSpecList.includes(row.id) && this.giftParams.goodsItemId !== row.id) {
        boolean = true
      }
      return boolean
    },
    /** 是否可以多选 */
    selectable(row) {
      let boolean = true;
      if (this.form.selectList.length && !this.form.selectList.filter(({ id }) => row.id === id).length) {
        boolean = false;
      }
      if (this.useSpecList.length && this.useSpecList.includes(row.id) && this.giftParams.goodsItemId !== row.id) {
        boolean = false;
      }
      return boolean;
    },
    /** 更改已被选择的背景颜色 */
    tableRowClassName({ row }) {
      if (this.form.selectList && this.form.selectList.filter(({ id }) => row.id === id).length) {
        return 'disable-row';
      }
      return '';
    },
    /** 创建赠品活动 */
    req_addGift(params) {
      this.$moe_api.GIFTMANAGE_API.addGift(params).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新建成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.giftLoad = false;
      });
    },
    /** 修改赠品活动 */
    req_updateGift(params) {
      this.$moe_api.GIFTMANAGE_API.updateGift(params).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('编辑成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.giftLoad = false;
      });
    },
    /** 查询已使用的商品sku */
    req_getGiftUseSkus(goodsId) {
      this.$moe_api.GIFTMANAGE_API.getGiftUseSkus({ goodsId }).then((data) => {
        if (data.code === 200) {
          this.form.useSpecList = data.result.usedGoodsItemIds;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 获取赠品活动详情 */
    req_getGiftDetail() {
      if (this.$route.query.id) {
        this.$moe_api.GIFTMANAGE_API.getGiftDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let { id, name, goodsId, goodsItemId, validDays, stockTotal, stockLeft } = data.result;
            this.giftParams = {
              id,
              name,
              goodsId,
              goodsItemId,
              validDays,
              stockTotal,
              goodsList: [],
            };

            this.req_queryShopGoodsById('detail', { id: goodsId, goodsItemId, stockTotal, stockLeft });
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 查询商品详情 */
    req_queryShopGoodsById(source, { id, goodsItemId, stockTotal, stockLeft }, index) {
      this.req_getGiftUseSkus(id);
      this.form.goodsId = id;
      this.$moe_api.GOODS_API.queryShopGoodsById({ id }).then((data) => {
        if (data.code === 200) {
          let r = data.result;
          let goodsItem = this.$moe_lodash.cloneDeepData(r.goodsItem);
          goodsItem = goodsItem.filter(({ delFlag }) => delFlag === 0);
          switch (source) {
            case 'detail':
              this.giftParams.goodsList = [{
                ...r,
                specList: goodsItem.filter((specItem) => specItem.id === goodsItemId).map((specItem) => {
                  return {
                    ...specItem,
                    stockTotal,
                  }
                }),
                stockTotal,
                stockLeft
              }]
              break;
            default:
              var findList = this.giftParams.goodsList[index].specList;
              if (findList.length) {
                this.form.defaultSelectIds = findList.map(({ id }) => id);
                this.form.specList = goodsItem.map((item) => {
                  const findItem = findList.find(({ id }) => item.id === id);
                  return {
                    ...item,
                    stockTotal: findItem?.stockTotal || '',
                  }
                })
              } else {
                this.form.specList = goodsItem.map((item) => {
                  return {
                    ...item,
                    stockTotal: '',
                  }
                })
                this.form.defaultSelectIds = [];
              }
              this.form.showDialog = true;
              break;
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    this.req_getGiftDetail();
  },
}
</script>

<style></style>